import { Action } from "redux";
import { all, delay, put, takeEvery } from "redux-saga/effects";
import { addToastToState, removeToast, showErrorToast, showInfoToast, showSuccessToast, ToastStyle } from "./ToastSlice";
import { v4 as uuidv4 } from "uuid";

export default function* toastSagas() {
  yield all([
    watchShowToast(),
  ]);
}

function* watchShowToast() {
  yield takeEvery([
    showErrorToast,
    showInfoToast,
    showSuccessToast,
  ], showToastAsync);
}

function* showToastAsync(action: Action) {
  let style = ToastStyle.Info;

  if (showErrorToast.match(action)) {
    style = ToastStyle.Error;
  } else if (showSuccessToast.match(action)) {
    style = ToastStyle.Success;
  } else if (showInfoToast.match(action)) {
    style = ToastStyle.Info;
  } else {
    return;
  }

  let id = uuidv4();

  yield put(addToastToState({
    id,
    message: action.payload,
    style,
  }));

  yield delay(5000);

  yield put(removeToast(id));
}