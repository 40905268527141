import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import usersApi from "api/users-api/UsersApi";
import { Action } from "redux";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { RootState } from "store/store";
import { IUserView } from "types/userTypes";
import { deleteUser, finishDeletingUser, finishLoadingUserList, loadUserList } from "./UserListSlice";

export function* userListSagas() {
  yield all([
    loadUserListAsync(),
    deleteUserAsync(),
  ]);
}

function* loadUserListAsync() {
  yield takeLatest(loadUserList, function* (action: Action) {
    if (!loadUserList.match(action)) {
      return;
    }

    try {
      let pagedItems: IPagedResult<IUserView> = yield call(usersApi.getUsers,
        action.payload.pageSize,
        action.payload.pageNumber);

      yield put(finishLoadingUserList({
        isWorking: false,
        data: pagedItems,
      }));
    } catch (err) {
      yield put(finishLoadingUserList({
        isWorking: false,
        errorMessage: getResponseErrorMessage(err),
      }));
    }
  });
}

function* deleteUserAsync() {
  yield takeLatest(deleteUser, function* (action: Action) {
    if (!deleteUser.match(action)) {
      return;
    }

    try {
      yield call(usersApi.deleteUser, action.payload.email);

      yield put(finishDeletingUser(true));

      const pageSize: number = yield select((store: RootState) => store.userList.pageSize);

      // Reload user list.
      yield put(loadUserList({
        pageNumber: 1,
        pageSize,
      }));

      yield put(showSuccessToast("Profile deleted successfully."));
    } catch (err) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      yield put(finishDeletingUser(false));
    }
  });
}