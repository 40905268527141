import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import { toastSlice } from "shared/store/toast/ToastSlice";
import rootSaga from "store/RootSaga";
import { currentUserSlice } from "./current-user/CurrentUserSlice";
import { manageRiskSlice } from "./manage-risk/ManageRiskSlice";
import { riskListSlice } from "./risk-list/RiskListSlice";
import { userListSlice } from "./user-list/UserListSlice";

let sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    currentUser: currentUserSlice.reducer,
    toast: toastSlice.reducer,
    manageRisk: manageRiskSlice.reducer,
    riskList: riskListSlice.reducer,
    userList: userListSlice.reducer,
  },
  middleware: (getDefaultMiddleWare) => getDefaultMiddleWare().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;