import { all } from "@redux-saga/core/effects";
import toastSagas from "shared/store/toast/ToastSagas";
import { manageRiskSagas } from "./manage-risk/manageRiskSagas";
import { riskListSagas } from "./risk-list/riskListSagas";
import { userListSagas } from "./user-list/userListSagas";

export default function* rootSaga() {
  yield all([
    toastSagas(),
    manageRiskSagas(),
    riskListSagas(),
    userListSagas(),
  ]);
}