import AdminRoute from "components/auth/AdminRoute";
import ManageRiskPage from "components/risks/manage-risk/ManageRiskPage";
import RiskListPage from "components/risks/risk-list/RiskListPage";
import UsersPage from "components/users/UsersPage";
import React from "react";
import {
  Navigate,
  Route,
  Routes as Switch
} from "react-router-dom";
import NotFound from "./NotFound";

export enum Routes {
  Root = "/",

  RiskList = "/risks",
  NewRiskReport = "/risks/new",
  ManageRiskReport = "/risks/:riskId",
  Users = "/users",
}

const Routing: React.FC = () =>
  <Switch>
    <Route
      path={Routes.ManageRiskReport}
      element={
        <ManageRiskPage
          mode="edit"
        />
      }
    />

    <Route
      path={Routes.NewRiskReport}
      element={
        <ManageRiskPage
          mode="new"
        />
      }
    />

    <Route
      path={Routes.RiskList}
      element={
        <RiskListPage />
      }
    />

    <Route
      path={Routes.Users}
      element={
        <AdminRoute>
          <UsersPage />
        </AdminRoute>
      }
    />

    <Route
      path={Routes.Root}
      element={
        <Navigate
          to={Routes.RiskList}
        />
      }
    />

    <Route
      path={Routes.Root}
      element={
        <NotFound />
      }
    />
  </Switch>;

export default Routing;