import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import "components/table-list/TableList.scss";
import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import PreAuthScreen from 'shared/components/auth/PreAuthScreen';
import Banner, { BannerType } from 'shared/components/common/banner/Banner';
import "shared/components/GlobalStyles.scss";
import PortalContext from 'shared/components/layout/portal/PortalContext';
import { store } from 'store/store';
import App from './App';
import { authRequest, msalPublicClient } from "./auth/auth-config";
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <React.StrictMode>
    <MsalProvider
      instance={msalPublicClient}
    >
      <PortalContext.Provider
        value={document.getElementById('portal')}
      >
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={(err) => (
            <Banner
              type={BannerType.error}
            >
              Failed to authenticate: {err.error?.errorMessage || JSON.stringify(err)}
            </Banner>
          )}
          loadingComponent={() => (
            <PreAuthScreen
              message="Logging in..."
            />
          )}
        >
          <Provider
            store={store}
          >
            <App />
          </Provider>
        </MsalAuthenticationTemplate>
      </PortalContext.Provider>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
