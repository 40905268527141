import React, { useEffect } from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import ConfirmModal from "shared/components/common/confirm-modal/ConfirmModal";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import Spinner from "shared/components/common/spinner/Spinner";
import Card from "shared/components/layout/card/Card";
import FlexCol from "shared/components/layout/flex/FlexCol";
import Page from "shared/components/layout/page/Page";
import Table from "shared/components/layout/table/Table";
import deleteIcon from "shared/media/dls/delete.svg";
import editIcon from "shared/media/dls/edit-1.svg";
import { IColumnItem } from "shared/types/columnTypes";
import { useAppDispatch, useAppSelector } from "store/store";
import { deleteUser, loadUserList, setAskConfirmDeleteUser, setUserManager } from "store/user-list/UserListSlice";
import { IUserView } from "types/userTypes";
import "./UsersPage.scoped.scss";
import ManageUserModal from "./manage-modal/ManageUserModal";

const UsersPage: React.FC = () => {
  const {
    hasMore,
    page,
    pageSize,
    users,
    loadOperation,

    isUserManagerOpen,
    managedUser,
    confirmDeleteUser,
    isDeletingUser,
  } = useAppSelector(store => store.userList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadUserList({
      pageNumber: 1,
      pageSize: pageSize,
    }));
  }, [dispatch, pageSize]);

  let renderComponent: React.ReactNode | undefined;

  const renderColumns = (columnKey: string,
    boundObj: IUserView,
    objProperty: string) => {
    if (columnKey === "edit") {
      return getNodeCell((
        <img
          src={editIcon}
          alt="Edit"
          title="Edit"
          className="icon-small button"
          onClick={() => dispatch(setUserManager({
            isOpen: true,
            managedUser: boundObj,
          }))}
        />
      ), "edit");
    } else if (columnKey === "delete") {
      return getNodeCell((
        <img
          src={deleteIcon}
          alt="Delete"
          title="Delete"
          className="icon-small button"
          onClick={() => dispatch(setAskConfirmDeleteUser(boundObj))}
        />
      ), "delete");
    } else {
      let cellValue = (boundObj as any)[objProperty];

      return getTextCell(!cellValue
        ? ""
        : String(cellValue), columnKey);
    }
  };

  if (loadOperation?.isWorking
    && page === 1) {
    renderComponent = (
      <Spinner />
    );
  } else if (!loadOperation?.isWorking
    && loadOperation?.errorMessage) {
    renderComponent = (
      <Banner
        type={BannerType.error}
      >
        {loadOperation.errorMessage}
      </Banner>
    );
  } else if (users.length) {
    renderComponent = (
      <Table
        data={users}
        columns={getColumnDefinitions()}
        columnRender={renderColumns}
        fetchMoreData={() => dispatch(loadUserList({
          pageNumber: page,
          pageSize: pageSize,
        }))}
        showLoadingNextPage={true}
        isLoadingPage={loadOperation?.isWorking}
        hasMore={hasMore}
        height="100%"
        width="100%"
        className="table-list"
      />
    );
  } else {
    renderComponent = (
      <Banner
        type={BannerType.info}
      >
        No users found.
      </Banner>
    );
  }

  return (
    <FlexCol
      className="main-col"
    >
      <Page
        title="User Management"
        stretchChildren={true}
        headerControls={[{
          key: "filters",
          element: (
            <button
              className="secondary filter-button"
              onClick={() => dispatch(setUserManager({
                isOpen: true,
                managedUser: undefined,
              }))}
            >
              Assign Role
            </button>
          )
        }]
        }
      >
        <Card
          style={{
            flex: "auto",
            overflow: "auto",
          }}
        >
          {renderComponent}
        </Card>

        {isUserManagerOpen && (
          <ManageUserModal
            existingUser={managedUser}
            onClose={wasDataSaved => {
              dispatch(setUserManager({
                isOpen: false,
                managedUser: undefined,
              }));

              if (wasDataSaved) {
                dispatch(loadUserList({
                  pageNumber: 1,
                  pageSize,
                }));
              }
            }}
          />
        )}

        {!!confirmDeleteUser && (
          <ConfirmModal
            header="Confirm Delete"
            message={
              <FlexCol>
                <span>Are you sure you want to delete this user? This operation cannot be undone.</span>
                <span>{confirmDeleteUser.name} ({confirmDeleteUser.email})</span>
              </FlexCol>
            }
            onNoClicked={() => dispatch(setAskConfirmDeleteUser(undefined))}
            onYesClicked={() => dispatch(deleteUser(confirmDeleteUser))}
          />
        )}

        {isDeletingUser && <ModalSpinner />}
      </Page>
    </FlexCol>
  );
};

export default UsersPage;

function getColumnDefinitions(): IColumnItem<IUserView, keyof IUserView>[] {
  return [
    { header: "", key: "edit", headerCellClassName: "cell-cmd", },
    { header: "Name", key: "name", property: "name", },
    { header: "Email", key: "email", property: "email", },
    { header: "Role", key: "role", property: "role", },
    { header: "", key: "delete", headerCellClassName: "cell-cmd", },
  ];
}

function getNodeCell(node: React.ReactNode, key: string) {
  return (
    <td
      key={`cell-${key}`}
      className={`cell-${key}`}
    >
      {node}
    </td>
  );
}

function getTextCell(text: string, key: string) {
  return (
    <td
      className={`cell-${key}`}
      key={`cell-${key}`}
      title={text}
    >
      {text || ""}
    </td>
  );
}