import React from "react";
import Banner, { BannerType } from "shared/components/common/banner/Banner";
import { useAppSelector } from "store/store";
import { Roles } from "types/userTypes";

interface IAdminRouteProps {
  children: React.ReactNode,
}

const AdminRoute: React.FC<IAdminRouteProps> = (props) => {
  const currUser = useAppSelector(x => x.currentUser.currentUser);
  const isAdmin = currUser.roles.some(x => x.role === Roles.Admin);

  if (!isAdmin) {
    return (
      <Banner
        type={BannerType.error}
      >
        You do not have permission to view this area.
      </Banner>
    );
  }

  return (
    <>
      {props.children}
    </>
  );
};

export default AdminRoute;