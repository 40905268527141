import { authDelete, authGetJson, authPostJson } from "auth/authFetches";
import config from "config";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { IAzureADUser } from "shared/types/userTypes";
import { throwIfResponseError } from "shared/utilities/apiUtilities";
import { ICurrentUser, IUserView, Roles } from "types/userTypes";
import { formatGetUsersResponse, formatMyProfile, formatSearchAzureADUsersResponse, formatUserView } from "./userFormatters";

class UsersApi {
  public async searchADUsers(searchTerm: string): Promise<IAzureADUser[]> {
    const response = await authGetJson(`${config.endpoints.users.searchADUsers}?searchTerm=${searchTerm}`);
    throwIfResponseError(response);
    return formatSearchAzureADUsersResponse((await response.json()));
  }

  public async getMyProfile(email: string): Promise<ICurrentUser> {
    const response = await authGetJson(config.endpoints.users.myProfile.replace(":email", email));

    if (response.status === 404) {
      throw new Error("Profile not found.");
    } else {
      await throwIfResponseError(response);
    }

    return formatMyProfile(await response.json());
  }

  public async getUsers(pageSize: number, page: number): Promise<IPagedResult<IUserView>> {
    const response = await authGetJson(`${config.endpoints.users.getUsers}?page=${page}&pageSize=${pageSize}`);

    await throwIfResponseError(response);

    return formatGetUsersResponse(await response.json());
  }

  public async assignUserRole(assignmentInfo: { name: string, email: string, role?: Roles; }): Promise<IUserView> {
    const response = await authPostJson(config.endpoints.users.assignUserRole, {
      name: assignmentInfo.name,
      email: assignmentInfo.email,
      role: assignmentInfo.role,
    });

    await throwIfResponseError(response);

    return formatUserView(await response.json());
  }

  public async deleteUser(email: string): Promise<void> {
    const response = await authDelete(config.endpoints.users.deleteUser.replace(":email", email));

    await throwIfResponseError(response);
  }
}

const usersApi = new UsersApi();
export default usersApi;