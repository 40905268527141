import { isArray } from "lodash";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { IAzureADUser } from "shared/types/userTypes";
import { ICurrentUser, IUserView } from "types/userTypes";

export function formatSearchAzureADUsersResponse(obj: any): IAzureADUser[] {
  return isArray(obj)
    ? obj.map((x: any): IAzureADUser => formatUser(x))
    : [];
}

export function formatUser(obj: any): IAzureADUser {
  return {
    name: obj.name,
    email: obj.email,
  };
}

export function formatMyProfile(obj: any): ICurrentUser {
  return {
    name: obj.name,
    email: obj.email,
    roles: isArray(obj.roles)
      ? obj.roles
      : [],
  };
}

export function formatGetUsersResponse(obj: any): IPagedResult<IUserView> {
  let items = (isArray(obj.results)
    ? (obj.results) as any[]
    : []).map(x => formatUserView(x));

  return {
    currentPage: Number(obj.currentPage),
    pageSize: Number(obj.pageSize),
    results: items,
  };
}

export function formatUserView(obj: any): IUserView {
  return {
    name: obj.name?.toString() ?? "",
    email: obj.email?.toString() ?? "",
    role: obj.role?.toString() ?? "",
  };
}