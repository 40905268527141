import usersApi from "api/users-api/UsersApi";
import UsersEditorModal from "components/risks/manage-risk/users-editor/UsersEditorModal";
import React, { useState } from "react";
import ModalSpinner from "shared/components/common/spinner/ModalSpinner";
import LabeledControl from "shared/components/controls/labeled-control/LabeledControl";
import FlexCol from "shared/components/layout/flex/FlexCol";
import FlexRow from "shared/components/layout/flex/FlexRow";
import Modal from "shared/components/layout/modal/Modal";
import { showErrorToast, showSuccessToast } from "shared/store/toast/ToastSlice";
import { IAzureADUser } from "shared/types/userTypes";
import { getResponseErrorMessage } from "shared/utilities/apiUtilities";
import { useAppDispatch } from "store/store";
import { IUserView, Roles } from "types/userTypes";

interface IManageUserModalProps {
  existingUser?: IUserView,
  onClose(wasNewDataSaved: boolean): void,
}

const ManageUserModal: React.FC<IManageUserModalProps> = ({
  existingUser,
  onClose,
}: IManageUserModalProps) => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<IAzureADUser | undefined>(existingUser
    ? {
      email: existingUser.email,
      name: existingUser.name,
    } : undefined
  );
  const [isUserSearchOpen, setIsUserSearchOpen] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<Roles | undefined>(existingUser?.role === Roles.Admin.toString()
    ? Roles.Admin
    : undefined);
  const [isWorking, setIsWorking] = useState<boolean>(false);

  const onSave = async () => {
    if (!user) {
      return;
    }

    setIsWorking(true);

    try {
      await usersApi.assignUserRole({
        name: user.name,
        email: user.email,
        role: selectedRole,
      });

      dispatch(showSuccessToast("Role assigned successfully."));

      onClose(true);
    } catch (err) {
      dispatch(showErrorToast(getResponseErrorMessage(err)));
      setIsWorking(false);
    }
  };

  return (
    <Modal
      header={existingUser
        ? "Update Role"
        : "Assign Role"
      }
      isOpen={true}
      width={400}
      buttons={[{
        key: "cancel",
        text: "Cancel",
        className: "secondary",
        onClick: () => onClose(false),
      }, {
        key: "save",
        text: "Assign",
        className: "primary",
        disabled: !user
          || (existingUser
            && existingUser.role === (selectedRole ?? "Default User")),
        onClick: onSave,
      }]}
    >
      <FlexCol>
        <LabeledControl
          label="User"
          isRequired={!existingUser}
        >
          <div
            className={!existingUser
              ? "input pointer"
              : undefined
            }
            onClick={existingUser
              ? undefined
              : () => setIsUserSearchOpen(true)
            }
          >
            {user
              ? <>{user.name} ({user.email})</>
              : <span className="placeholder">Select User</span>
            }
          </div>
        </LabeledControl>

        <LabeledControl
          label="Role"
          isRequired
        >
          <FlexRow style={{ gap: "1em" }}>
            <label>
              <input
                type="radio"
                value="Default User"
                checked={selectedRole === undefined}
                onChange={() => setSelectedRole(undefined)}
              />
              Default User
            </label>

            <label>
              <input
                type="radio"
                value="Admin"
                checked={selectedRole === Roles.Admin}
                onChange={() => setSelectedRole(Roles.Admin)}
              />
              Admin
            </label>
          </FlexRow>
        </LabeledControl>

        {isUserSearchOpen && (
          <UsersEditorModal
            currentUsers={user ? [user] : []}
            onClose={() => setIsUserSearchOpen(false)}
            onSave={(users) => {
              setIsUserSearchOpen(false);
              setUser(users[0]);
            }}
            title="User"
          />
        )}

        {isWorking && <ModalSpinner />}
      </FlexCol>
    </Modal>
  );
};

export default ManageUserModal;