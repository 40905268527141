import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOperation } from "shared/types/operationTypes";
import { IPagedResult } from "shared/types/pageResultsTypes";
import { IUserView } from "types/userTypes";

export interface IUserListState {
  users: IUserView[],
  loadOperation?: IOperation<IPagedResult<IUserView>>,
  page: number,
  pageSize: number,
  hasMore: boolean,

  isUserManagerOpen: boolean,
  managedUser?: IUserView,

  confirmDeleteUser?: IUserView,
  isDeletingUser: boolean,
}

const initialState: IUserListState = {
  users: [],
  loadOperation: {
    isWorking: true,
  },
  page: 1,
  pageSize: 200,
  hasMore: false,

  isUserManagerOpen: false,
  managedUser: undefined,

  confirmDeleteUser: undefined,
  isDeletingUser: false,
};

export const userListSlice = createSlice({
  name: "user-list",
  initialState,
  reducers: {
    loadUserList: (state, _: PayloadAction<{
      pageNumber: number,
      pageSize: number,
    }>) => {
      state.loadOperation = {
        isWorking: true,
      };
    },
    finishLoadingUserList: (state, action: PayloadAction<IOperation<IPagedResult<IUserView>>>) => {
      if (action.payload.errorMessage
        || action.payload.data === undefined) {
        state.loadOperation = action.payload;
        return;
      }

      if (action.payload.data.currentPage === 1) {
        state.users = [];
        state.page = 1;
      }

      const newItems = action.payload.data.results;
      state.users.push(...newItems);
      state.hasMore = newItems.length === action.payload.data.pageSize;
      state.page = action.payload.data.currentPage + 1;

      state.loadOperation = undefined;
    },
    setUserManager: (state, action: PayloadAction<{
      isOpen: boolean,
      managedUser?: IUserView,
    }>) => {
      state.isUserManagerOpen = action.payload.isOpen;
      state.managedUser = action.payload.managedUser;
    },
    setAskConfirmDeleteUser: (state, action: PayloadAction<IUserView | undefined>) => {
      state.confirmDeleteUser = action.payload;
    },
    deleteUser: (state, _: PayloadAction<IUserView>) => {
      state.isDeletingUser = true;
    },
    finishDeletingUser: (state, action: PayloadAction<boolean>) => {
      state.isDeletingUser = false;
      state.confirmDeleteUser = action.payload ? undefined : state.confirmDeleteUser;
    },
  },
});

export const {
  loadUserList,
  finishLoadingUserList,
  setUserManager,
  setAskConfirmDeleteUser,
  deleteUser,
  finishDeletingUser,
} = userListSlice.actions;
